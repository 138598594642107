import React from 'react';
import LoginBack from './components/Login/Loginbak';
import { BrowserRouter } from 'react-router-dom';

function App() {


  return (
    <BrowserRouter>
    <LoginBack />
  </BrowserRouter>
  );
}

export default App;
