

import React from 'react';

import './chatcontentstyle.css';

function ChatVacio() {


  return (
    <div className='contevacio'>

      <div >
        
      </div>
    </div>
  );
}

export default ChatVacio;
